html {
   overflow-x: hidden !important; 
}
body {
    font-family: $body-font-family;
    a {
        text-decoration: underline;
    }
    overflow-x: hidden !important;
}

a {
    text-decoration: underline;

    color: $link-color;

    &.warning-link {
        font-family: $montserrat-font;
        font-size: .7rem;

        text-decoration: underline;
        text-transform: uppercase;
    }
}
button {
    outline: none !important;
    &:focus,
    &:hover {
        outline: none !important;
    }
}
hr {
    border-color: #000;
}

.container,
.row {
    position: relative;
}

.row.expanded {
    margin: 0 -30px;
}

.b-lazy {
    @include transition(opacity .15s ease-in);
    @include backface-visibility(hidden);

    max-width: 100%;

    opacity: 0;

    will-change: opacity;
    &.b-loaded {
        opacity: 1;
    }
}

.img-responsive {
    display: block;

    width: 100%;
}

@keyframes fade-in {
    from {
        transform: translateY(10%);

        opacity: 0;
    }
    to {
        transform: translateY(0);

        opacity: 1;
    }
}

.element-animate {
    animation: fade-in 1.4s;
}

.animation-do {
    opacity: 0;

}
.reveal-overlay {
    background-color: rgba(0, 0, 0, 0);
    .reveal {
        outline: none;
        background-color: rgba(19, 44, 58, 0.9);
        color: #ffffff;
        border-color: transparent;
        text-align: center;
        top: 50% !important;
        margin-top: -115px;
        
        padding: 3rem 2rem;

        @include breakpoint(small down) {
            top: auto !important;
            margin-top: auto !important;
        }
        p {
            font-size: rem-calc(18);
            margin-bottom: 1rem;
            margin-top: 1rem;
        }
        button {
            color: #ffffff;
            font-family: $montserrat-font;
            font-size: rem-calc(18);
            text-decoration: underline;
            cursor: pointer;
        }
        h1 {
            text-transform: uppercase;
            font-family: $montserrat-font;
            font-size: rem-calc(18);
            @include breakpoint(small down) {
                font-weight: 600 !important;
            }
        }
        a {
            font-family: $montserrat-font;
            color: #ffffff;
            font-size: rem-calc(18);
            background-color: transparent !important;
            text-transform: uppercase;
            span {
                background-color: transparent !important;
            }
            &.lang-switch {
                position: absolute;
                top: 20px;
                left: 20px;
            }  
            @include breakpoint(small down) {
                font-weight: 600 !important;
            }
        }
        
    }
}


.generic-content-container {
    .container {
        .row {
            // background-color: blue;
        }
    }
}

.fixed-back-to-top {
    position: fixed;
    right: 7%;
    top: 50%;
    margin-top: 30px;
    width: 140px;
    height: 30px;
    text-align: right;
    display: none;

    
    a {
        color: black;
    }
    span {
        color: black;
    }

}

video::-webkit-media-controls {
    display:none !important;
    -webkit-appearance: none;
}
*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}
 
*::--webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}
 
*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}