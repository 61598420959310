body 
{
	.contatos {
		padding-top: 3rem;
		p {
			text-indent: 0 !important;
			margin-bottom: 2rem;
		}

		h2 {
			font-family: $montserrat-font;
			text-transform: uppercase;
			font-size: 0.8rem;
			font-weight: 400;
			padding-left: 3rem;
			margin-bottom: 1rem;
			@include breakpoint(small down) {
				font-weight: 600;
			}
		}
	}
	.header-contatos {
		color: #000;
		.nav-menu .hamburger .hamburger-box .hamburger-inner, .nav-menu .hamburger .hamburger-box .hamburger-inner:after, .nav-menu .hamburger .hamburger-box .hamburger-inner:before {
			background-color: #000;
		}
		a {
			color: #000;
		}
	}
}