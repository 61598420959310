#footer {
	font-family: $montserrat-font;
	font-weight: 600;
	padding-bottom: 2rem;
	padding-top: 1rem;
	a {
		text-decoration: none;
	}
	.social-network {
		
		text-align: right;
	
		padding-right: 0.1rem;
		text-align: right;
		li {
			display: inline-block;
			list-style: none;
			margin-right: 1rem;
			&:last-child {
				margin-right: 0;
			}
		}
		
	}
	.footer-inner-container {
		.row.bordered {
			margin-top: 1rem;
			border-top: 3px $main-color solid;
			padding-top: 0.5rem;
		}	
	} 
	.footer-menu {
		text-align: right;
		float: right;
		text-transform: uppercase;
		font-size: 0.5rem;
		padding-right: 0.2rem;
		li {
			list-style: none;
			float: left;
			margin-right: 2rem;
			&:last-child {
				margin-right: 0;
			}
			@media screen and (max-width: 39.9375em) {
				float: none;
				margin-right: 0px;
				padding-right: 0px;
			}
		}
		@media screen and (max-width: 39.9375em) {
			margin-right: 0px;
			padding-right: 0px;
		}

		a {

			&:hover {
				text-decoration: underline;
			}
		}
	}
	h1.logo {
		text-indent: -1999px;
		width: 133px;
		height: 28px;
		background-image: url(/frontend/images/adelaide-logo-dark.svg);
		background-position: 0px 0px;

	}
	&.contact-page {
		background-color: $main-color;
		color: $grey;
		.row.bordered {
			border-color: $grey;	
		}
		
		a {
			color: $grey;
		}
		h1.logo {
			background-image: url(/frontend/images/adelaide-logo-grey.svg);
		}
	}
	&.waiting-list {
		color: $main-color;
		background-color: $bkg-color-grey;
		a {
			color: $main-color;
		}
	}
}