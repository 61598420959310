#header {
	position: relative;

	overflow: hidden;

	box-sizing: border-box; // padding-top: 2rem;
	// padding-bottom: 2rem;
	// min-height: 100%;

	color: $text-color;
	background-color: $main-color;
	&.waiting {
		padding-top: 7%;
	}
	&.header {
		height: 100vh;
		min-height: 500px;
	}
	h1.logo {
		@include breakpoint(medium down) {
			top: 27px;
			left: 3%;

			width: 150px;
			height: 31px;
		}

		position: fixed;
		z-index: 3;
		top: 20px;
		left: 8%;

		width: 228px;
		height: 48px;

		text-indent: -1999px;

		background-image: url(/frontend/images/adelaide-logo-white.svg);
		background-repeat: no-repeat;
		background-position: 0 0;
		//transition: all .3s ease-out;
		a {
			display: block;
		}
		&.dark {
			background-image: url(/frontend/images/adelaide-logo-dark.svg);
		}
	}
	.header-menu {
		font-family: $montserrat-font;

		position: absolute;
		z-index: 2;
		top: 250px;
		left: 8%;

		max-width: 460px;
		margin: 0;
		&:hover {
			li {
				opacity: .5;
			}
			li {
				&:hover {
					opacity: 1;
				}
			}
		}
		li {
			font-size: rem-calc(20);
			line-height: rem-calc(20);

			display: block;

			padding: 5px 0;

			transition: opacity .3s ease;
			text-transform: uppercase;

			color: #fff;
			&.high {
				font-size: rem-calc(34);
				line-height: rem-calc(30);
				a {
					text-decoration: none;
				}
			}
			a {
				padding-right: 5px;

				text-decoration: underline;
				text-transform: uppercase;

				color: #fff;
				span {
					font-size: 1.2rem;
				}
				@include breakpoint(small down) {
					font-weight: 600 !important;
				}
			}
		}
		@include breakpoint(small down) {
			display: none;
			li {
				font-size: rem-calc(15);
				line-height: rem-calc(15);
				&.high {
					font-size: rem-calc(18);
					line-height: rem-calc(18);
				}
			}
		}

	}

	&.contacts-header {
		background-color: #fff;
		h1.logo {
			background-image: url(/frontend/images/adelaide-logo-dark.svg);
		}
	}
	.video-fit-fullscreen {
		position: relative;

		display: block;

		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		video {
			z-index: -1;

			width: 100%;
			height: 100%;

			object-fit: cover;
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			/* IE10+ CSS styles go here */
				height: auto;
			}
			@supports (-ms-ime-align:auto) {
			    height: auto;
			}

		}
		a.icon-icon-scroll-down {
			position: absolute;
			bottom: .8rem;
			left: 50%;
			// background-color: red;
			font-size: 1.5rem;
			text-decoration: none;
			color: #ffffff;
			margin-left: -15px;
			transition: all .2s ease-out;
			transform: translatey(0px);
			animation: float 1s ease-out infinite;
		}
	}
	.video-controls-container {
		

		position: absolute;
		z-index: 2;
		top: 50%;
		left: 50%;

		width: 88px;
		height: 88px;
		margin-top: -44px;
		margin-left: -44px;
		a {
			font-size: 4.4rem;

			transition: opacity .2s ease-out;
			text-decoration: none;

			opacity: 1;
			color: #fff;
			&:hover {
				opacity: .1;
			}
		}
		&.home {
			display: none;
		}
		@include breakpoint(small down) {
			width: 44px;
			height: 44px;
			margin-top: -22px;
			margin-left: -22px;

			a {
				font-size: 2.2rem;
			}
			&.home {
				display: block;
			}
		}

	}

	.vallado-logo-home {
		@include breakpoint(medium down) {
			right: 2rem;
			bottom: 2rem;

			width: 80px;

			transition: all .5s ease-out;
			position: fixed;
		}

		position: absolute;
		right: 10rem;
		bottom: 5rem;

		transition: all .5s ease-out;
	}
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-10px);
	}
	100% {
		transform: translatey(0px);
	}
}

