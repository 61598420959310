@font-face {
    font-family: 'Adobe Caslon Pro';
    src: url('../fonts/ACaslonPro-Regular/subset-ACaslonPro-Regular.eot');
    src: url('../fonts/ACaslonPro-Regular/subset-ACaslonPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ACaslonPro-Regular/subset-ACaslonPro-Regular.woff2') format('woff2'),
        url('../fonts/ACaslonPro-Regular/subset-ACaslonPro-Regular.woff') format('woff'),
        url('../fonts/ACaslonPro-Regular/subset-ACaslonPro-Regular.ttf') format('truetype'),
        url('../fonts/ACaslonPro-Regular/subset-ACaslonPro-Regular.svg#ACaslonPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icons/icomoon.eot?wnm8p3');
  src:  url('fonts/icomoon.eot?wnm8p3#iefix') format('embedded-opentype'),
    url('../fonts/icons/icomoon.ttf?wnm8p3') format('truetype'),
    url('../fonts/icons/icomoon.woff?wnm8p3') format('woff'),
    url('../fonts/icons/icomoon.svg?wnm8p3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-icon-scroll-down:before {
  content: "\e900";
}
.icon-icon-arrow:before {
  content: "\e901";
}
.icon-icon-instagram:before {
  content: "\e902";
}
.icon-icon-pause:before {
  content: "\e903";
}
.icon-icon-play:before {
  content: "\e904";
}
.icon-icon-top-arrow:before {
  content: "\e905";
}
.icon-icon-vimeo:before {
  content: "\e906";
}
.icon-icon-youtube:before {
  content: "\e907";
}
