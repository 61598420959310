.waiting-list {
	background-color: $main-color;
	padding: 0;
	@include breakpoint(medium down) {
		padding-top: 2rem !important;
	}
	 > .padd {
		padding: rem-calc(30);
		@include breakpoint(small down) {
			padding: rem-calc(60) rem-calc(30);
		}
	}
	h1 {
		font-family: $montserrat-font;
		font-size: rem-calc(18);
		font-weight: 400;

		margin-bottom: 1rem;

		text-transform: uppercase;

		color: #aaa;
		@include breakpoint(small down) {
			font-weight: 600 !important;
			
		}
	}
	p {
		color: #aaa;
	}
	a {
		color: #aaa;
	}
	.pages-tab {
		overflow: hidden;
		.content-text {
			overflow: hidden;

			height: 0;

			transition: opacity .3s ease-out, transform .2s ease-out;
			transform: translateX(100%);

			opacity: 0;
			&.is-active {
				height: auto;

				transform: translateX(0);

				opacity: 1;
			}
			p {
				font-size: rem-calc(16);

				color: #000;
			}
		}
	}
	.menu-status {
		font-size: .8rem;
		font-weight: 400;

		display: block;

		margin-top: 60px;

		text-transform: uppercase;
	}
	.menu-list {
		margin: 0;
		li {
			font-size: rem-calc(18);
			font-weight: 400;

			display: block;
			float: none;

			margin-bottom: 5px;
			&.is-active,
			&:hover {
				background: none;
				a {
					text-decoration: underline;
				}
			}
			a {
				font-size: rem-calc(18);
				font-weight: 400;
				line-height: rem-calc(24);

				text-decoration: none;
				text-transform: uppercase;

				color: #000;
				outline: 0;
				&:hover {
					text-decoration: underline;

					background: none;
				}
			}
		}
	}
	img {
		@include breakpoint(small down) {
			width: 100px;
		}
	}
}
