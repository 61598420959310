main {
	padding: rem-calc(30);
}
* {
	&.no-indent {
		text-indent: 0 !important;
		p {
			text-indent: 0 !important;
		}
	}
}
p {
	margin-bottom: 0;

	text-indent: 8%;
}
hr {
	&.large {
		border-width: 2px;
	}
}
.goTop {
	display: inline-block;

	margin: 0 auto;

	cursor: pointer;
	a {
		font-size: rem-calc(16);
		line-height: rem-calc(22);

		display: inline-block;

		text-decoration: underline;

		color: #000;
	}
	span {
		display: inline-block;
	}
}

.content-small p {
	font-size: rem-calc(18);
	line-height: rem-calc(26);

	padding: 0 rem-calc(10);
}
blockquote {
	@include breakpoint(small down) {
		font-size: rem-calc(20);
		line-height: rem-calc(30);
	}

	font-family: $montserrat-font;
	font-size: rem-calc(27);
	line-height: rem-calc(30);

	margin: 0;
	cite {
		font-family: $adobe-caslon-font;
		font-size: 1.2rem;
		font-weight: 100;
		font-style: normal;
		@include breakpoint(small down) {
			font-size: 1rem;
		}
	}
	&.no-indent,
	.no-indent {
		text-indent: 0 !important;
	}
	&.quote-block {
		p {
			@include breakpoint(small down) {
				font-weight: 600;
			}
		}
	}
	&.quote-block-medium,
	&.quote-block-medium p {
		@include breakpoint(small down) {
			font-size: rem-calc(20);
			line-height: rem-calc(30);
		}

		font-size: rem-calc(28);
		line-height: rem-calc(30);

		text-indent: 15%;

		cite {
			text-indent: 15%;
		}
	}
	&.quote-block-large,
	&.quote-block-large p {
		@include breakpoint(small down) {
			font-size: rem-calc(20);
			line-height: rem-calc(30);
			font-weight: 600;
		}

		font-size: rem-calc(30);
		line-height: rem-calc(42);
	}
	&.quote-block-bold,
	&.quote-block-bold p {
		//font-size: rem-calc(28);
		font-weight: 400;
		line-height: rem-calc(40);

		text-indent: 0;
		cite {
			margin-top: rem-calc(40);
		}
		@include breakpoint(small down) {
			font-weight: 600;
		}
	}
	&.margin-up-element {
		margin-top: rem-calc(-60);
	}
	cite {
		margin-top: rem-calc(20);
	}
	&.serif {
		font-family: $adobe-caslon-font !important;
	}
}

figure {
	&.element-up {
		margin-top: -300px;
	}
	&.full {
		display: block;

		width: 100%;
	}
	figcaption,
	.figcaption {
		font-family: $montserrat-font;
		font-size: rem-calc(12);
		font-weight: 400;

		margin-top: rem-calc(10);

		text-align: right;
	}
}
