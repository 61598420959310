.columns-container {
	
	.small-12.medium-4 {
		
		&:first-child { 
			img {
				max-width: 50%;
				margin-right: 15px;
				margin-bottom: 5px;
				margin-left: -25px;
				float: left;
			}
		}
		img {
			max-width: 60%;
			text-align: center;
			margin-top: 15px;
			float: none;
		}
	}
}	

.technical-charts-container {
	background-color: $main-color;
	color: #fff;
	min-height: 650px;
	padding-bottom: rem-calc(100);
	.bottles-container {
		position:relative;
		@include breakpoint(small down) {
			min-height: 470px;
			position: relative;
		}
		@include breakpoint(small up) {
			position: static;
		}
		.bottle {
			position: absolute;
			transition: opacity .15s ease-out, transform .5s ease-out;
			transform: translateX(-5%);
			opacity:0;
			&.is-active {
				opacity: 1;
				transition: opacity .2s ease-out, transform .4s ease-out;
				transition-delay: .4s;
				transform: translateX(0);
			}
			@include breakpoint(medium down) {
				
				width: 270px;
				left: 50%;
				margin-left: -200px;
				
			}
			@include breakpoint(medium) {
				top: -250px;
				width: 380px;
				left: -50px;
			}
			@include breakpoint(medium up) {
				top: -250px;
				width: 380px;
				left: -55px;
			}
			@include breakpoint(large up) {
				top: -235px;
				left: -165px;
				width: 380px;
			}
		}
		
	}
	.tabs-content {
		background-color: transparent;
		a {
			color: #fff;
		}
		.tabs-panel {
			font-size: rem-calc(15);

			color: #fff !important;
			overflow: hidden;

			p {
				font-size: rem-calc(14);
				margin-bottom: rem-calc(20);
				text-indent: 0;
			}
			h5 {
				margin-bottom: rem-calc(5);
				text-transform: uppercase;
				font-size: rem-calc(15);
			}
		}
	}
	.tabs {
		background-color: transparent;	
		font-family: $montserrat-font;
		.tabs-title {
			&.is-active {
				a {
					outline: none !important;
					color: #fff !important;
				}
			}
			margin-right: 1rem;
			a {
				transition: color .2s ease-out;
				outline: none !important;
				color: #929292;
				font-size: rem-calc(26);
				font-weight: 400;
				text-decoration: none;
				background-color: transparent;
				&.disabled {
					opacity: .2;
					pointer-events: none;
  					cursor: default; 
				}
			}
		}
	}
	a.buy {
		color: #929292;
		display: block;
		&:hover {
			text-decoration: none;
		}
	}
	.wine-bottles-container {
		// padding-left: 13rem;
		// padding-top: 2rem;
		// @include breakpoint(medium) {
		// 	padding-left: 12rem;
		// }
	}
	h4,.tabs-title a, h5 {
		@include breakpoint(small down) {
			font-weight: 600 !important;
		}
	}
}