

.nav-menu {
	font-family: $montserrat-font;

	position: fixed;
	z-index: 2;
	top: 0;
	right: 0;

	display: block;
	// overflow: hidden;

	width: 80px;
	height: 80px;
	&.is-waiting-nav {
		.nav-bg {
			@include breakpoint(medium down) {
				@include background-image(linear-gradient(to bottom, $main-color 30%, rgba(19, 44, 58, 0.9) 50%, rgba(19, 44, 58, 0.6) 75%, rgba(19, 44, 58, 0) 100%));
				position: fixed;
				z-index: -1;
				top: 0;
				right: 0;
				left: 0;
				display: block;
				width: 100%;
				height: 190px;
				opacity: 1;
			}
			
		}
	}
	.nav-bg {
		@include breakpoint(medium down) {
			@include background-image(linear-gradient(to bottom, rgba(255,255,255,1) 30%, rgba(255,255,255,.9) 64%, rgba(255,255,255,.6) 75%, rgba(255,255,255,0) 100%));


			
			position: fixed;
			z-index: -1;
			top: 0;
			right: 0;
			left: 0;

			display: block;

			width: 100%;
			height: 190px;

			transition: opacity .2s ease-out;

			opacity: 0;
		}

		display: none;
	}

	&.dark {
		.nav-bg {
			@include breakpoint(medium down) {
				opacity:1;
			}
		}
		&.is-waiting-nav {
		
			.nav-bg {
				@include breakpoint(medium down) {
					@include background-image(linear-gradient(to bottom, rgba(215,215,215,1) 30%, rgba(215,215,215,.9) 50%, rgba(215,215,215,.6) 75%, rgba(215,215,215,0) 100%));
				}
			}
		}
	}
	.hamburger {
		font: inherit;

		position: absolute;
		z-index: 1;
		top: 20px;
		right: 0;

		display: inline-block;
		overflow: visible;

		margin: 0;
		padding: 15px;

		cursor: pointer;
		transition-timing-function: linear;
		transition-duration: .15s;
		transition-property: opacity, filter;
		text-transform: none;

		color: inherit;
		border: 0;
		background-color: transparent;
		&:hover {
			opacity: .7;
		}
		.hamburger-box {
			position: relative;

			display: inline-block;

			width: 40px;
			height: 24px;

			.hamburger-inner {
				top: 50%;

				display: block;

				margin-top: -2px;

				transition-timing-function: cubic-bezier(.55, .055, .675, .19);
				transition-duration: 75ms;
			}

			.hamburger-inner,
			.hamburger-inner:after,
			.hamburger-inner:before {
				position: absolute;

				width: 27px;
				height: 2px;

				transition-timing-function: ease;
				transition-duration: .15s;
				transition-property: transform;

				border-radius: 4px;
				background-color: #fff;
			}

			.hamburger-inner:after,
			.hamburger-inner:before {
				display: block;

				content: '';
			}

			.hamburger-inner:before {
				top: -10px;

				transition: top 75ms ease .12s, opacity 75ms ease;
			}

			.hamburger-inner:after {
				bottom: -10px;

				transition: bottom 75ms ease .12s, transform 75ms cubic-bezier(.55, .055, .675, .19);
			}
		}
	}

	&.is-active {
		@include breakpoint(medium down) {
			z-index: 5;
			&.dark {
				&:before {
					background-color: rgba(255,255,255,.9);
				}
			}
			&:before {
				position: fixed;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;

				width: 100%;
				height: 100%;
				margin: 0;
				padding: 0;

				content: '';

				background-color: rgba($main-color, .85);
			}
		}
		@include breakpoint(small down) {
			width: 100%;
			height: 100%;
		}

		width: auto;
		height: auto;
		 > ul {
			margin-right: 30px;

			transform: translateX(0);

			opacity: 1;
		}

		.hamburger-box .hamburger-inner {
			transition-delay: .12s;
			transition-timing-function: cubic-bezier(.215, .61, .355, 1);
			transform: rotate(45deg);
		}
		.hamburger-box .hamburger-inner:before {
			top: 0;

			transition: top 75ms ease, opacity 75ms ease .12s;

			opacity: 0;
		}
		.hamburger-box .hamburger-inner:after {
			bottom: 0;

			transition: bottom 75ms ease, transform 75ms cubic-bezier(.215, .61, .355, 1) .12s;
			transform: rotate(-90deg);
		}
	}

	 > ul {
		z-index: 1;

		display: inline-block;
		float: right;

		max-width: 300px;
		margin-top: 100px;

		transition: transform .3s ease-in, opacity .3s ease-out;
		transform: translateX(100%);

		opacity: 0;
		&:hover {
			li {
				a {
					opacity: .5;
				}
			}
			li {
				a {
					&:hover {
						opacity: 1;
					}
				}
			}
		}
		 > li {
			padding: 10px 0;

			border-top: 2px solid #fff;
			ul {
				margin-left: 0;
			}
			
		}
		li {
			font-size: rem-calc(16);
			line-height: rem-calc(18);

			display: block;

			padding-right: 1rem;

			text-transform: uppercase;

			color: #fff;

			a {
				padding-right: 5px;

				transition: opacity .3s ease;
				text-decoration: none;
				text-transform: uppercase;

				color: #fff;
				@include breakpoint(small down) {
					font-weight: 600 !important;
				}
			}
			@include breakpoint(small down) {
				margin-bottom: .1rem;
				padding-top: .3rem;
			}
		}
	}
	&.dark {
		color: $main-color;
		.hamburger-box .hamburger-inner {
			transition: all .3s ease-out;

			background-color: $main-color;
			&:before,
			&:after {
				transition: all .3s ease-out;

				background-color: $main-color;
			}
		}
		 > ul li {
			border-color: $main-color;
			a {
				color: $main-color;
			}
		}
	}
}
