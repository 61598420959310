.social-responsability {
	padding-left: 0;
	padding-right: 0;
	> .padd {
		padding: rem-calc(30);
		@include breakpoint(small down) {
			padding: rem-calc(60) rem-calc(30);
		}
	}
	.main-color-bg {
		color: #ffffff;
		padding-bottom: 17rem;
	}
	blockquote p {
		color: #ffffff !important;	
		// font-size: 1rem;

	}
	.bottom-image-container {
		margin-top: -15rem;
	}
	img.logo {
		max-width: 90% !important;
	}
	@include breakpoint(medium down) {
		img.logo {
			max-width: 30% !important;
		}
	}

}
.header-image {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center top;
	blockquote {
		position: absolute;
		z-index: 1;
		bottom: 100px;
		width: 50%;
		left: 50%;
		margin-left: -25%;
		p {
			color: #ffffff;
			font-weight: 700;
			padding-right: 7rem;
		}
		

	}
	@include breakpoint(medium down) {
		blockquote {
			position: absolute;
			z-index: 1;
			bottom: 100px;
			width: 80%;
			left: 30%;
			margin-left: -15%;
			p {
				color: #ffffff;
				font-weight: 700;
				padding-right: 4rem;
			}
			

		}
	}
}