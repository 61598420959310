.main-color-bg {
	background-color: $main-color;
}

.grey-color-bg {
	background-color: $bkg-color-grey;
}

.main-color-text {
	color: $main-color !important;
	a {
		text-decoration: underline;

		color: $main-color;
		&:hover {
			text-decoration: none;
		}
	}
	&.bold {
		font-weight: bold;

		text-transform: uppercase;
	}
	p {
		color: $main-color !important;
	}
}

.white-color-bg {
	background-color: #fff;
}

.white-color-text {
	color: #fff;
}

.letter-space-1 {
	letter-spacing: 1px;
}

.letter-space-2 {
	letter-spacing: 2px;
}

.letter-space-3 {
	letter-spacing: 3px;
}

.letter-space-4 {
	letter-spacing: 4px;
}

.font-300 {
	font-weight: 300;
}

.font-400 {
	font-weight: normal;
}

.font-500 {
	font-weight: 500;
	@include breakpoint(small down) {
		
		font-weight: 600;
	}
}
.font-600 {
	@include breakpoint(small down) {
		
		font-weight: 600;
	}
}
.font-700,
.font-bold {
	font-weight: bold;
}

.font-900 {
	font-weight: 900;
}

.text-uppercase {
	text-transform: uppercase;
}

.inline-d {
	display: inline-block;
}

.z1 {
	z-index: 1;
}
.z2 {
	z-index: 2;
}
.z3 {
	z-index: 3;
}
.z4 {
	z-index: 4;
}
.z5 {
	z-index: 5;
}

@include generate-aligns();
@include generate-margins();
@include generate-paddings();
