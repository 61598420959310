.river {
	
	.multiple-plans {
		position: relative;
		img.plan {
			position: absolute;
		}
		img.guide {
			opacity: 0;
		}
		
		width: 100%;
	}
}
