form.form {
	[type='text'],
	[type='password'],
	[type='date'],
	[type='datetime'],
	[type='datetime-local'],
	[type='month'],
	[type='week'],
	[type='email'],
	[type='number'],
	[type='search'],
	[type='tel'],
	[type='time'],
	[type='url'],
	[type='color'],
	textarea,
	select {
		font-size: rem-calc(20);
		line-height: rem-calc(26);

		height: rem-calc(40);
		margin: 0 0 rem-calc(10);
		padding: rem-calc(10) rem-calc(10) rem-calc(10) 0;

		color: #fff;
		border: none;
		border-bottom: 1px solid #aaa;
		background-color: transparent;
		box-shadow: none;
		&.is-invalid-input {
			border-bottom: 1px solid #cc4b37;
		}
	}
	select {
		height: rem-calc(45);

		.empty {
			color: #aaa;
		}
	}
	[type='submit'],
	[type='button'] {
		font-family: $montserrat-font;
		font-size: rem-calc(22);
		font-weight: bold;
		line-height: rem-calc(30);

		margin: 0;
		padding: 0;

		text-decoration: underline;
		text-transform: uppercase;

		color: #fff;
		border: none;
		background-color: transparent;
	}
	.error {
		font-family: $montserrat-font;
		font-size: rem-calc(16);
		line-height: rem-calc(22);

		display: none;

		margin: rem-calc(10) 0 0;

		text-indent: 0;

		color: #cc4b37;
	}

	.msg {
		font-family: $montserrat-font;
		font-size: rem-calc(16);
		line-height: rem-calc(22);

		display: none;

		margin: rem-calc(10) 0 0;

		text-indent: 0;

		color: #fff;
		&.show {
			display: block;
		}
	}
}
