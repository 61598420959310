@include breakpoint(large up) {
	figure.image-type-1 {
		position: absolute;

		margin-top: rem-calc(40);
	}
}

.line-border-bottom {
	max-width: 430px;
	margin: rem-calc(20) 0;

	border-bottom: 1px solid #000;
}

ul.list-names {
	margin: rem-calc(10) 0;

	text-align: left;
	li {
		font-size: rem-calc(26);
		line-height: rem-calc(40);

		display: block;
		@include breakpoint(small down)
		{
			font-size: 1rem;
			line-height: 1.5rem;
		}
	}

}

.list-names-description {
	font-size: rem-calc(16);

	display: block;

	text-align: left;
}

.family {
	.adelaide-multiple-plans {
		position: relative;
		img.plan {
			position: absolute;
		}
		img.guide {
			opacity: 0;
		}
		
		width: 100%;
	}
}
